/* eslint-disable  */

export default {
    "Vizulo": "Vizulo",
    "luminaires": "luminaires",
    "Menu": "Menu",
    "Select language": "Select language",
    "About us": "About us",
    "Account": "Account",
    "Request access": "Request access",
    "Dashboard": "Dashboard",
    "Sign out": "Sign out",
    "Add new address": "Add new address",
    "Additional Information": "Additional Information",
    "Apply": "Apply",
    "Reset filter": "Reset filter",
    "Attention!": "Attention!",
    "Back to home": "Back to home",
    "Back to homepage": "Back to homepage",
    "Billing": "Billing",
    "Billing address": "Billing address",
    "Cancel": "Cancel",
    "Cart": "Cart",
    "All": "All",
    "I agree": "I agree",
    "Select...": "Select...",
    "Show more": "Show more",
    "Project": "Project",
    "Assortment": "Assortment",
    "Product photometry": "Product photometry",
    "Price list": "Price list",
    "Instruction": "Instruction",
    "Categories": "Categories",
    "Indoor": "Indoor",
    "Outdoor": "Outdoor",
    "Choose product": "Choose product",
    "Product configurator": "Product configurator",
    "Our products": "Our products",
    "Coming soon": "Coming soon",
    "New": "New",
    "Back to login page": "Back to login page",
    "All orders": "All orders",
    "Explore all optics": "Explore all optics",
    "Cable price calculator": "Cable price calculator",
    "Last updated on": "Last updated on",
    "Best selling articles": "Best selling articles",
    "Other options available on request": "Other options available on request",
    "Inspired by nature and developed to create a better and safer lighting for people and the environment.": "Inspired by nature and developed to create a better and safer lighting for people and the environment.",
    "Change": "Change",
    "Change password your account": "If you want to change the password to access your account, enter the following information",
    "Clear all": "Clear all",
    "Color": "Color",
    "Contact details updated": "Keep your addresses and contact details updated.",
    "Contact us": "Contact us",
    "Continue to billing": "Continue to billing",
    "Continue to payment": "Continue to payment",
    "Continue to delivery": "Continue to delivery",
    "Cookies Policy": "Cookies Policy",
    "Create an account": "Create an account",
    "Default Billing Address": "Default Billing Address",
    "Default Delivery Address": "Default Delivery Address",
    "Delete": "Delete",
    "Description": "Description",
    "Discount": "Discount",
    "Done": "Done",
    "Download": "Download",
    "Download all": "Download all",
    "Edit": "Edit",
    "Email address": "Email address",
    "Empty bag": "Empty bag",
    "Filters": "Filter",
    "Find out more": "Find out more",
    "First Name": "First Name",
    "Forgot Password": "If you can’t remember your password, you can reset it.",
    "Forgot Password Modal Email": "Email you are using to sign in:",
    "forgotPasswordConfirmation": "Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.",
    "Forgot password?": "Forgot password?",
    "Go back": "Go back",
    "Go back shopping": "Go back shopping",
    "Go back to shop": "Go back to shop",
    "Go to checkout": "Go to checkout",
    "Guarantee": "Guarantee",
    "Help": "Help",
    "Help & FAQs": "Help & FAQs",
    "hide": "hide",
    "Home": "Home",
    "I agree to": "I agree to",
    "I confirm subscription": "I confirm subscription",
    "I want to create an account": "I want to create an account",
    "It was not possible to request a new password, please check the entered email address.": "It was not possible to request a new password, please check the entered email address.",
    "Item": "Item",
    "Items": "Items",
    "Last Name": "Last Name",
    "Log into your account": "Log into your account",
    "Login": "Login",
    "go to login page": "go to login page",
    "Go to login page": "Go to login page",
    "Make an order": "Make an order",
    "My billing and delivery address are the same": "My billing and delivery address are the same",
    "My Cart": "My Cart",
    "Don't have an account yet?": "Don't have an account yet?",
    "or": "or",
    'Or': 'Or',
    "or fill the details below": "or fill the details below",
    "Order No.": "Order No.",
    "Order summary": "Order summary",
    "Other products you might like": "Other products you might like",
    "Out of stock": "Out of stock",
    "Password": "Password",
    "Password Changed": "Password successfuly changed. You can now go back to homepage and sign in.",
    "Pay for order": "Pay for order",
    "Payment": "Payment",
    "Payment & Delivery": "Payment & Delivery",
    "Payment method": "Payment method",
    "Payment methods": "Payment methods",
    "Personal details": "Personal details",
    "Please type your current password to change your email address.": "Please type your current password to change your email address.",
    "Price": "Price",
    "Privacy": "Privacy",
    "Privacy Policy": "Privacy Policy",
    "Product": "Product",
    "Products": "Products",
    "Products found": "Products found",
    "Quantity": "Quantity",
    "Read and understand": "I have read and understand the",
    "Read reviews": "Read reviews",
    "Register": "Register",
    "Remove Address": "Remove Address",
    "Remove from Wishlist": "Remove from Wishlist",
    "Repeat Password": "Repeat Password",
    "Reset Password": "Reset Password",
    "Review my order": "Review my order",
    "Same as delivery address": "Same as delivery address",
    "Save changes": "Save changes",
    "Save for later": "Save for later",
    "Save Password": "Save Password",
    "Search": "Search",
    "Search...": "Search...",
    "Search for items": "Search for items",
    "Search results": "Search results",
    "See all results": "See all results",
    "Select payment method": "Select payment method",
    "Select delivery term": "Select delivery term",
    "Delivery": "Delivery",
    "Delivery address": "Delivery address",
    "Delivery details": "Delivery details",
    "Delivery term": "Delivery term",
    "Delivery terms": "Delivery terms",
    "Show":"Show",
    "show more": "show more",
    "Show on page": "Show on page",
    "Sign in": "Sign in",
    "Sign Up for Newsletter": "Sign Up for Newsletter",
    "Sort by": "Sort by",
    "Sort: Default": "Default",
    "Sort: Name A-Z": "Name A-Z",
    "Sort: Name Z-A": "Name Z-A",
    "Sort: Price from high to low": "Price from high to low",
    "Sort: Price from low to high": "Price from low to high",
    "Start shopping": "Start shopping",
    "Subscribe": "Subscribe",
    "Subscribe to newsletter": "Subscribe to newsletter",
    "subscribeToNewsletterModalContent": "After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.",
    "Subtotal": "Subtotal",
    "Subtotal price": "Subtotal price",
    "Successful placed order": "You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.",
    "Terms and conditions": "Terms and conditions",
    "Total": "Total",
    "Total items": "Total items",
    "Total price": "Total price",
    "Update password": "Update password",
    "Update personal data": "Update personal data",
    "User Account": "User Account",
    "View": "View",
    "View details": "View details",
    "Who we are": "Who we are",
    "Wishlist": "Wishlist",
    "You are not authorized, please log in": "You are not authorized, please log in",
    "You can unsubscribe at any time": "You can unsubscribe at any time",
    "You currently have no orders": "You currently have no orders",
    "You haven't searched for items yet": "You haven’t searched for items yet.",
    "Your bag is empty": "Your bag is empty",
    "Your current email address is": "Your current email address is",
    "Your email": "Your email",
    "The email field must be a valid email":"The email field must be a valid email",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
    "A customer with the same email address already exists in an associated website.": "A customer with the same email address already exists in an associated website.",
    "Invalid email": "Invalid email",
    "Learn more": "Learn more",
    "Shop now": "Shop now",
    "Subscribe to Newsletters": "Subscribe to Newsletters",
    "My Account": "My Account",
    "My profile": "My profile",
    "Personal Details": "Personal Details",
    "Addresses details": "Addresses details",
    "My newsletter": "My newsletter",
    "Log out": "Log out",
    "My reviews": "My reviews",
    "Order history": "Order history",
    "Order details": "Order details",
    "My wishlist": "My wishlist",
    "Password change": "Password change",
    "Personal data": "Personal data",
    "Your e-mail": "Your e-mail",
    "Current Password": "Current Password",
    "You are not authorized, please log in.": "You are not authorized, please log in.",
    "Go To Product": "Go To Product",
    "Change to list view": "Change to list view",
    "Change to grid view": "Change to grid view",
    "My orders": "My orders",
    "Add the address": "Add the address",
    "Set as default delivery": "Set as default delivery",
    "Set as default billing": "Set as default billing",
    "Street Name": "Street Name",
    "City": "City",
    "State/Province": "State/Province",
    "Zip-code": "Zip-code",
    "Country": "Country",
    "Phone number": "Phone number",
    "Please select a country first":"Please select a country first",
    "This field is required": "This field is required",
    "The field should have at least 2 characters": "The field should have at least 2 characters",
    "The field should have at least 4 characters": "The field should have at least 4 characters",
    "The field should have at least 8 characters": "The field should have at least 8 characters",
    "New Password": "New Password",
    "New Products": "New Products",
    "There are no shipping terms available for this country. We are sorry, please try with different country.": "There are no shipping terms available for this country. We are sorry, please try with different country.",
    "There was some error while trying to fetch shipping terms. We are sorry, please try with different shipping details.": "There was some error while trying to fetch shipping terms. We are sorry, please try with different shipping details.",
    "There was some error while trying to select this shipping term. We are sorry, please try with different shipping term.": "There was some error while trying to select this shipping term. We are sorry, please try with different shipping term.",
    "We can't find products matching the selection.":"We can't find products matching the selection.",
    "Page not found": "Page not found",
    "Back to Home page": "Back to Home page",
    "An error occurred": "An error occurred",
    "AllProductsFromCategory": "All {categoryName}",
    "Countries": "Countries",
    "Go to References": "Go to References",
    "About Vizulo": "About Vizulo",
    "Explore": "Explore",
    "Filter": "Filter",
    "Back": "Back",
    "Our news": "Our news",
    "Explore products": "Explore products",
    "luminaire": "luminaire",
    "Luminaire": "Luminaire",
    "Archive": "Archive",
    "Riga": "Riga",
    "Iecava": "Iecava",
    "Join our team": "Join our team",
    "Vacancies in Vizulo": "Vacancies in Vizulo",
    "Other vacancies": "Other vacancies",
    "Our top cases": "Our top cases",
    "References": "References",
    "Exported to Countries": "Exported to Countries",
    "Our lighting products are exported to 43 countries all over the world.": "Our lighting products are exported to 43 countries all over the world.",
    "We are proud to share our the most significant, largest reference projects all around the world": "We are proud to share our the most significant, largest reference projects all around the world",
    "Catalogue Interior 2020": "Catalogue Interior 2020",
    "Catalogue Exterior 2022": "Catalogue Exterior 2022",
    "Related Products": "Related Products",
    "View product": "View product",
    "Message": "Message",
    "Phone": "Phone",
    "Name": "Name",
    "Send": "Send",
    "Email": "Email",
    "Submit your CV": "Submit your CV",
    "Upload file": "Upload file",
    "Send CV": "Send CV",
    "Luminaires": "Luminaires",
    "Vizulo values": "Vizulo values",
    "Product Overview": "Product Overview",
    "Specification": "Specification",
    "Download Documents": "Download Documents",
    "Specifications": "Specifications",
    "Features": "Features",
    "Accessories": "Accessories",
    "See all references": "See all references",
    "About project": "About project",
    "Reference": "Reference",
    "Show references": "Show references",
    "Show all products": "Show all products",
    "Show all countries": "Show all countries",
    "Filter by application": "Filter by application",
    "Filter by type": "Filter by type",
    "Basic colours": "Basic colours",
    "Possible any colours": "Possible any colours",
    "Sitemap": "Sitemap",
    "Reset": "Reset",
    "Search for typical options": "Search for typical options",
    "Members": "Members",
    "℃": "℃",
    "mm": "mm",
    "Download datasheet": "Download datasheet",
    "Customize datasheet": "Customize datasheet",
    "Project": "Project",
    "Customer": "Customer",
    "Position": "Position",
    "Download customized datasheet": "Download customized datasheet",
    "Price list accessories": "Price list accessories",
    "Show accessories": "Show accessories",
    "Article": "Article",
    "results": "results",
    "Lens application group filter": "Lens application group filter",
    "Search...": "Search...",
    "Core count": "Core count",
    "Cross sectional area": "Cross sectional area",
    "Length": "Length",
    "Cable length": "Cable length",
    "Min 100 pcs": "Min 100 pcs",
    "Company name": "Company name",
    "Company address": "Company address",
    "Company position": "Company position",
    "Telephone": "Telephone",
    "VAT number": "VAT number",
    "My account": "My account",
    "LED and driver configuration filters" : "LED and driver configuration filters",
    "Dimming": "Dimming",
    "Socket": "Socket",
    "Yes": "Yes",
    "No": "No",
    "Product configurator": "Product configurator",
    "Light": "Light",
    "Electrics and control": "Electrics and control",
    "Optimization": "Optimization",
    "Module type": "Module type",
    "Finishing touches": "Finishing touches",
    "Standard": "Standard",
    "Version": "Version",
    "Console": "Console",
    "Lens": "Lens",
    "There where no lenses found": "There where no lenses found",
    "Color rendering index": "Color rendering index",
    "Color temperature": "Color temperature",
    "Constant light output": "Constant light output",
    "Initial output": "Initial output",
    "Lifetime": "Lifetime",
    "Flux": "Flux",
    "Power": "Power",
    "Control and protection": "Control and protection",
    "Insulation": "Insulation",
    "Surge protection device": "Surge protection device (12 kV)",
    "LED module thermal protection with NTC": "LED module thermal protection with NTC",
    "Extra colors": "Extra colors",
    "Layers": "Layers",
    "Mounting screw length": "Mounting screw length",
    "Step": "Step",
    "socket": "socket",
    "None": "None",
    "Selected": "Selected",
    "Min driver surge protection": "Min driver surge protection",
    "Driver rank": "Driver rank",
    "Max diode current": "Max diode current",
    "Min power factor": "Min power factor",
    "Got it": "Got it",
    "privacy policy": "privacy policy",
    "Submit": "Submit",
    "Configuration completed": "Configuration completed!",
    "efficacy": "efficacy",
    "Min luminous efficacy": "Min luminous efficacy",
    "Model number": "Model number",
    "No configuration found! Please try adjusting or disabling filters below.": "No configuration found! Please try adjusting or disabling filters below.",
    "Datasheet": "Datasheet",
    "LDT file": "LDT file",
    "IES file": "IES file",
    "Product version": "Product version",
    "LED module type": "LED module type",
    "LED quantity": "LED quantity",
    "Start over": "Start over",
    "This step is optional and can be skipped": "This step is optional and can be skipped",
    "Next step": "Next step",
    "Diode quantity": "Diode quantity",
    "Min driver power utilization": "Min driver power utilization",
    "LEDs": "LEDs",
    "Driver features": "Driver features",
    "Driver availability rank": "Driver availability rank",
    "Searching for matching configurations. It might take a while.": "Searching for matching configurations. It might take a while.",
    "Thermal protection with NTC": "Thermal protection with NTC",
    "Dimming schedule": "Dimming schedule",
    "Output level": "Output level",
    "Time": "Time",
    "On": "On",
    "Off": "Off",
    "Efficacy": "Efficacy",
    "Application": "Application",
    "LED density": "LED density",
    "Distribution type": "Distribution type",
    "Product reference": "Product reference",
    "reserved": "reserved",
    "Reserved prices": "Reserved prices",
    "Product photometry table": "Product photometry table",
    "Project reference": "Project reference",
    "Reserve article": "Reserve article",
    "Edit configuration": "Edit configuration",
    "Login to reserve": "Login to reserve",
    "Login to reserve article": "Login to reserve article",
    "Create": "Create",
    "My products": "My products",
    "Power step (W)": "Power step (W)",
    "Offset (%)": "Offset (%)",
    "Generate multiple files per configuration": "Generate multiple files per configuration",
    "reserve": "reserve",
    "Reserve": "Reserve",
    "Step dimming parameters": "Step dimming parameters",
    "Switch-on level": "Switch-on level",
    "SD level": "SD level",
    "Min level": "Min level",
    "Max level": "Max level",
    "Max voltage": "Max voltage",
    "Min voltage": "Min voltage",
    "Mains dimming parameters": "Mains dimming parameters",
    "Ja vēlies būt daļa no VIZULO izaugsmes stāsta, atstāj mums savu CV. Mēs to rūpīgi izskatīsim un sazināsimies ar Tevi tiklīdz mums būs vajadzīgs tieši tāds darbinieks kā Tu.": "Ja vēlies būt daļa no VIZULO izaugsmes stāsta, atstāj mums savu CV. Mēs to rūpīgi izskatīsim un sazināsimies ar Tevi tiklīdz mums būs vajadzīgs tieši tāds darbinieks kā Tu.",
    "Apstiprinu, ka esmu iepazinies ar darbinieku privātuma politiku un tās saturs man ir saprotams.": "Apstiprinu, ka esmu iepazinies ar darbinieku privātuma politiku un tās saturs man ir saprotams.",
    "Privātuma politika pieejama": "Privātuma politika pieejama",
    "šeit": "šeit",
    "Piekrītu, ka personas datus saglabāt un izmantot citos amata konkursos divus gadus pēc to iesniegšanas. Šo piekrišanu Jūs varat nedot un tās nedošana neierobežos Jūsu iespēju piedalīties konkursā uz konkrēto amatu.": "Piekrītu, ka personas datus saglabāt un izmantot citos amata konkursos divus gadus pēc to iesniegšanas. Šo piekrišanu Jūs varat nedot un tās nedošana neierobežos Jūsu iespēju piedalīties konkursā uz konkrēto amatu.",
    "Varbūt pazīsti kādu, kurš būtu ideāls kandidāts? Informē viņu!": "Varbūt pazīsti kādu, kurš būtu ideāls kandidāts? Informē viņu!",
    "Vakance vairs nav aktuāla": "Vakance vairs nav aktuāla",
    "OR": "OR",
    "Select product": "Select product",
    "No configuration found, please check if number is correct.": "No configuration found, please check if number is correct.",
    "No configuration found! Please consider making changes to your selection.": "No configuration found! Please consider making changes to your selection.",
    "Remove": "Remove",
    "Are you sure you would like to remove this item from the shopping cart?": "Are you sure you would like to remove this item from the shopping cart?",
    "Go to delivery": "Go to delivery",
    "Add to cart": "Add to cart",
    "Mounting": "Mounting",
    "Enter photometry number:": "Enter photometry number:",
    "Configure": "Configure",
    "Report issue": "Report issue",
    "LED module category": "LED module category",
    "copy": "copy",
    "copied": "copied",
    "Tender text": "Tender text",
    "Comments": "Comments",
    "Describe the issue in detail": "Describe the issue in detail",
    "No attributes available in this step for": "No attributes available in this step for",
    "Use this address as my default one.": "Use this address as my default one.",
    "There was some error while trying to select this shipping term. We are sorry, please try with a different shipping term.": "There was some error while trying to select this shipping term. We are sorry, please try with a different shipping term.",
    "There are no shipping terms available for this country. We are sorry, please try with a different country.": "There are no shipping terms available for this country. We are sorry, please try with a different country.",
    "Reach us by e-mail, phone, or social networks to become our client or get more information.": "Reach us by e-mail, phone, or social networks to become our client or get more information.",
    "Mail": "Mail",
    "Accounting": "Accounting",
    "Phone": "Phone",
    "Get in Touch": "Get in Touch",
    "Sales": "Sales",
    "Production": "Production",
    "Go to Map": "Go to Map",
    "Lighting design team": "Lighting design team",
    "Enquiries regarding photometric files and lighting calculations.": "Enquiries regarding photometric files and lighting calculations.",
    "Request form": "Request form",
    "Join our newsletter": "Join our newsletter",
    "You'll be first to know about exciting new products and events. We promise not to spam.": "You'll be first to know about exciting new products and events. We promise not to spam.",
    "Valid email is required": "Valid email is required",
    "Thank you for signing up!": "Thank you for signing up!",
    "Date": "Date",
    "Required fields": "Required fields",
    "Show other filters": "Show other filters",
    "Isoline view": "Isoline view",
    "Polar view": "Polar view",
    "Member approval": "Member approval",
    "Member email": "Member email",
    "Approve": "Approve",
    "Enter email of a registered member you want to approve": "Enter email of a registered member you want to approve",
    "Typical": "Typical",
    "Locked": "Locked",
    "Download selected as zip": "Download selected as zip",
    "Download all as zip": "Download all as zip",
    "Saves product configuration and generates an article that can be used to order configured product.": "Saves product configuration and generates an article that can be used to order configured product.",
    "Power factor": "Power factor",
    "configure": "configure",
    "Get complete product configuration and (or) datasheet.": "Get complete product configuration and (or) datasheet.",
    "Datasheet file format": "Datasheet file format",
    "Choose your current location": "Choose your current location",
    "New photometry (recommended)": "New photometry (recommended)",
    "Legacy photometry": "Legacy photometry",
    "Login to download datasheet in docx format": "Login to download datasheet in docx format",
    "When we first started – as a team of 10 people in 2013 – we decided that the core values of Vizulo would be flexibility, passion to win, speed, quality, design, and innovation. Standing by these values allowed us to grow, realizing largest street lighting modernization projects in Northern Europe.": "When we first started – as a team of 10 people in 2013 – we decided that the core values of Vizulo would be flexibility, passion to win, speed, quality, design, and innovation. Standing by these values allowed us to grow, realizing largest street lighting modernization projects in Northern Europe.",
    "In the beginning, I thought Vizulo would be delivering lighting solutions to ensure the highest energy efficiency for our clients. Nowadays, we use different types of sensors and smart lighting management systems to distribute light where and when it\'s needed. So, in a curious way - these solutions are often more about darkness than light!": "In the beginning, I thought Vizulo would be delivering lighting solutions to ensure the highest energy efficiency for our clients. Nowadays, we use different types of sensors and smart lighting management systems to distribute light where and when it\'s needed. So, in a curious way - these solutions are often more about darkness than light!",
    "Up to": "Up to",
    "News": "News",
    "Vacancies": "Vacancies",
    "Contacts": "Contacts",
    "Orders": "Orders",
    "Lighting calculation requests": "Lighting calculation requests",
    "Our management": "Our management",
    "Certificates and Awards": "Certificates and Awards",
    "Search by Name...": "Search by Name...",
    "First Page": "First Page",
    "Last Page": "Last Page",
    "next": "next",
    "prev": "prev",
    "Rows per page": "Rows per page",
    "of": "of",
    "page": "page",
    "All": "All",
    "Fill the form and become a member of vizulo.com": "Fill the form and become a member of vizulo.com",
    "To become a member you must fill this form. Our team will review it and give positive or negative answer to your request. All fields are required.": "To become a member you must fill this form. Our team will review it and give positive or negative answer to your request. All fields are required.",
    "Basket": "Basket",
    "Downloading...": "Downloading...",
    "Max Ta": "Max Ta",
    "Highest efficacy": "Highest efficacy",
    "Target configuration": "Target configuration",
    "Best price": "Best price",
    "Optimal configuration": "Optimal configuration",
    "Hide other filters": "Hide other filters",
    "Lens is missing photometric files!": "Lens is missing photometric files!",
    "Remember me": "Remember me",
    "When enabled increase session length to 32 hours and reset it after every usage.": "When enabled increase session length to 32 hours and reset it after every usage.",
    "Select format": "Select format",
    "Download might take some time depending on your connection speed.": "Download might take some time depending on your connection speed.",
    "Change log": "Change log",
    "Company VAT number": "Company VAT number",
    "Your position in company": "Your position in company",
    "Thank you for registering! However, our team will review your request and give positive or negative answer to it via email.": "Thank you for registering! However, our team will review your request and give positive or negative answer to it via email.",
    "Office": "Office",
    "Company": "Company",
    "Webinars": "Webinars",
    "Available for users": "Available for users",
    "Switch to advanced mode to access this step.": "Switch to advanced mode to access this step.",
    "Advanced": "Advanced",
    "Basic": "Basic",
    "Login to unlock advanced mode": "Login to unlock advanced mode",
    "Switch to isoline view.": "Switch to isoline view.",
    "Switch to polar view.": "Switch to polar view.",
    "Training materials": "Training materials",
    "Request forms": "Request forms",
    "Academy": "Academy",
    "Name/Group": "Name/Group",
    "Manage company": "Manage company",
    "Select company": "Select company",
    "Add member to company": "Add member to company",
    "Enter email of a registered member you want to add": "Enter email of a registered member you want to add",
    "Add": "Add",
    "Remove member from this company?": "Remove member from this company?",
    "UL": "UL",
    "Thank you for registering!": "Thank you for registering!",
    "Add article": "Add article",
    "Enter existing article": "Enter existing article",
    "Select member": "Select member",
    "No products added yet.": "No products added yet.",
    "Add Vizulo manager": "Add Vizulo manager",
    "If a member with provided email exist, then they will be approved!": "If a member with provided email exist, then they will be approved!",
    "If a member with provided email exist, then they will be added to manager's group!": "If a member with provided email exist, then they will be added to manager's group!",
    "If a member with provided email exist, then they will be added to company!": "If a member with provided email exist, then they will be added to company!",
    "Show managers": "Show managers",
    "No orders yet.": "No orders yet.",
    "Delivered": "Delivered",
    "Created on": "Created on",
    "Status": "Status",
    "Order": "Order",
    "Company manager": "Company manager",
    "Customer order number": "Customer order number",
    "Location": "Location",
    "Corrosive environment": "Corrosive environment",
    "Requested delivery date": "Requested delivery date",
    "Warranty": "Warranty",
    "Category": "Category",
    "Created by": "Created by",
    "Additional info": "Additional info",
    "Save": "Save",
    "Go to basket": "Go to basket",
    "Or add new": "Or add new",
    "Go to My orders": "Go to My orders",
    "Internal": "Internal",
    "External": "External",
    "Update the address": "Update the address",
    "Register project": "Register project",
    "Preview map": "Preview map",
    "First time user requires validation code.": "First time user requires validation code.",
    "Please contact sales manager for further information.": "Please contact sales manager for further information.",
    "Validation code": "Validation code",
    "Close map": "Close map",
    "Add article to cart": "Add article to cart",
    "Invalid article": "Invalid article",
    "Seller": "Seller",
    "Add address": "Add address",
    "Set billing address": "Set billing address",
    "Add new project": "Add new project",
    "Or add new project": "Or add new project",
    "Add cable": "Add cable",
    "Cable": "Cable",
    "ESCO": "ESCO",
    "Type": "Type",
    "Edit cable": "Edit cable",
    "Update": "Update",
    "No delivery adress set yet.": "No delivery adress set yet.",
    "No billing adress set yet.": "No billing adress set yet.",
    "Move up": "Move up",
    "Move down": "Move down",
    "Approve order": "Approve order",
    "Continue to summary": "Continue to summary",
    "Payment term": "Payment term",
    "Payment terms": "Payment terms",
    "Save to address list": "Save to address list",
    "Missing billing address!": "Missing billing address!",
    "Our reference": "Our reference",
    "Customer reference": "Customer reference",
    "Configure dimming": "Configure dimming",
    "Midnight dimming": "Midnight dimming",
    "Mains dimming": "Mains dimming",
    "Step dimming": "Step dimming",
    "Product reference": "Product reference",
    "In stock": "In stock",
    "This field should be less than {target}": "This field should be less than {target}",
    "This field should be greater than {target}": "This field should be greater than {target}",
    "Request price": "Request price",
    "Not found": "Not found",
    "New arrivals in": "New arrivals in",
    "Lapwing family": "Lapwing family",
    "optional": "optional",
    "Order warranty has been changed to five years!": "Order warranty has been changed to five years!",
    "Warranty for products": "Warranty for products",
    "can't be higher than 5 years. Contact sales support to request increased warranty or lower warranty requirements.": "can't be higher than 5 years. Contact sales support to request increased warranty or lower warranty requirements.",
    "Loading flux and power slider": "Loading flux and power slider",
    "Compatible with": "Compatible with",
    "Side": "Side",
    "Top": "Top",
    "Overview": "Overview",
    "Controls & Connectivity": "Controls & Connectivity",
    "Optics": "Optics",
    "Electronics": "Electronics",
    "Latitude": "Latitude",
    "Longitude": "Longitude",
    "Registered luminaires": "Registered luminaires",
    "Claim and paid service request submission form": "Claim and paid service request submission form",
    "SO number": "SO number",
    "Next": "Next",
    "Enter valid order number and article combination to proceed. Both can be read from product label.": "Enter valid order number and article combination to proceed. Both can be read from product label.",
    "Multiple orders can be added to the form, but separated claim submissions with unique number will be created for each order.": "Multiple orders can be added to the form, but separated claim submissions with unique number will be created for each order.",
    "Contact us via claims@vizulo.com if you need help.": "Contact us via claims@vizulo.com if you need help.",
    "Order delivery date": "Order delivery date",
    "Warranty case": "Warranty case",
    "End user": "End user",
    "Preferred course of action": "Preferred course of action",
    "SO number must start with '21' followed by 6-10 digits.": "SO number must start with '21' followed by 6-10 digits.",
    "SO number can have at most 2 letters as a prefix.": "SO number can have at most 2 letters as a prefix.",
    "Article must be 8-10 digits long and contain only numbers.": "Article must be 8-10 digits long and contain only numbers.",
    "Installation date": "Installation date",
    "Failure date": "Failure date",
    "Issue category": "Issue category",
    "This field have more than 3 characters": "This field have more than 3 characters",
    "Please add at least one issue to proceed.": "Please add at least one issue to proceed.",
    "Full name": "Full name",
    "Vizulo rep. email address": "Vizulo rep. email address",
    "Issue list": "Issue list",
    "Claim / paid service request submitted": "Claim / paid service request submitted",
    "Please fill in all required fields.": "Please fill in all required fields.",
    "You will recieve an email with claim number shortly. Please follow its instructions": "You will recieve an email with claim number shortly. Please follow its instructions",
    "Upload Images": "Upload Images",
    "The date must be in the past.": "The date must be in the past.",
    "This field must be a number": "This field must be a number",
    "Entered SO number and article combination was not found. Please check that both numbers where entered correctly.": "Entered SO number and article combination was not found. Please check that both numbers where entered correctly.",
};
